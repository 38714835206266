<script setup>
import {
  onMounted,
  onUnmounted,
  ref,
} from 'vue'
import PdfPackageBuilder from './PdfBuilder'
import { usePostMessageListener } from '@/composables/usePostMessageListener'
import { transformHashKeys } from '@/lib/utils/Casing'

const builder = ref(null)
const formConfig = ref({})

const triggerHandler = (event) => {
  if (event?.data?.type !== 'kipu_pdf_package') return

  const { action, config } = event.data || {}

  try {
    if (action === 'open') {
      logger.debug('Opening PDF package', transformHashKeys(JSON.parse(config.casefileOptions)), JSON.parse(config.casefileOptions))
      formConfig.value = transformHashKeys(JSON.parse(config.casefileOptions))

      builder.value.openModal()
    }
  } catch (error) {
    logger.error('Error handling PDF package action', error, action, config)
  }
}

const unsubscribeListener = ref(usePostMessageListener(triggerHandler))
const subscribeListener = () => {
  if (unsubscribeListener.value) {
    unsubscribeListener.value()
  }

  unsubscribeListener.value = usePostMessageListener(triggerHandler)
}

onMounted(() => {
  subscribeListener()
})

onUnmounted(() => {
  unsubscribeListener.value()
})
</script>

<template>
  <PdfPackageBuilder
    ref="builder"
    :patient-id="formConfig?.patientId"
    :type="formConfig?.type || 'package'"
  />
</template>
