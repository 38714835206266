import { useMutationObserver } from '@/lib/utils/Mutations'

export const permanentElements = ['vue-3-app', 'headlessui-portal-root']

const mapper = new WeakMap()

export function useApplicationStack () {
  const main = document.querySelector('main[data-app]')

  function isHelpCenter (node) {
    return (node.tagName === 'IFRAME' && node.dataset.product === 'web_widget') ||
      (node.tagName === 'DIV' && (node.querySelector('#launcher') || node.querySelector('#webWidget'))) ||
      [...node.querySelectorAll('iframe')].some(iframe => iframe.contentWindow.document.querySelector('[data-embed=helpCenterForm]'))
  }

  function isPendo (node) {
    return ([...node.classList].findIndex(str => str.includes('pendo')) !== -1)
  }

  function isOnePassword (node) {
    return /1password/i.test(node.tagName)
  }

  function isProtected (node) {
    try {
      return node.nodeType !== Node.ELEMENT_NODE ||
        node === main ||
        node.hasAttribute('data-stacking-protected') ||
        permanentElements.includes(node.id) ||
        isPendo(node) ||
        isHelpCenter(node) ||
        isOnePassword(node) ||
        !!node.querySelector('iframe[src^="http"]')
    } catch (err) {
      logger.error('error checking node', err, node)
      return true
    }
  }

  function getAttributes (node) {
    try {
      const attrs = {}
      for (const attr of node.attributes) {
        attrs[attr.name] = attr.value
      }
      return attrs
    } catch (err) {
      logger.error('error getting attributes', err, node)
      return {}
    }
  }

  function moveToMain (node) {
    cancelMoveToMain(node)

    if (isProtected(node)) return false

    // main.appendChild(node)
    const timeout = setTimeout(() => {
      logger.debug('moving bad root node', node)
      if (node.parentElement === document.body) {
        const scrolled = []
        const getScrollTop = (el) => {
          if (el.scrollTop) {
            scrolled.push({ el, scrollTop: el.scrollTop })
          }
        }
        getScrollTop(node)
        node.querySelectorAll('*').forEach(getScrollTop)
        const frames = [...node.querySelectorAll('iframe')]
          .filter(frame => !frame.src || frame.src.startsWith('about:blank') || frame.src.startsWith('javascript:'))
          .filter(frame => !frame.classList.contains('js-iframe'))
          .map(frame => [frame, frame.contentWindow.document.documentElement.innerHTML, getAttributes(frame.contentWindow.document.documentElement)])
        const focused = document.activeElement
        main.appendChild(node)
        if (focused && node.contains(focused)) focused.focus()
        for (const { el, scrollTop } of scrolled) {
          el.scrollTop = scrollTop
        }
        for (const [frame, html, attrs] of frames) {
          if (!node.contains(frame)) continue
          try {
            for (const [key, value] of Object.entries(attrs)) {
              frame.contentWindow.document.documentElement.setAttribute(key, value)
            }
            if (html) frame.contentWindow.document.documentElement.innerHTML = html
          } catch (err) {
            logger.error('error restoring iframe', err, frame, html, attrs)
          }
        }
      }
    }, 1000)
    mapper.set(node, () => clearTimeout(timeout))
  }

  function cancelMoveToMain (node) {
    mapper.get(node)?.()
  }

  const observer = useMutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      mutation.addedNodes.forEach(moveToMain)
      mutation.removedNodes.forEach(cancelMoveToMain)
    })
  }, { keepAlive: true })

  observer.observe(document.body, {
    childList: true,
    subtree: false,
  })
  document.body.childNodes.forEach(moveToMain)

  return observer
}
