import { usePostMessage } from '@/composables/usePostMessageListener'

export const kisEngineOverrides = (opts) => {
  opts = Object.assign({}, window.Kipu || {}, opts || {})

  if (opts.newPdfBuilder) {
    window.KisEngine = window.KisEngine || {}

    /*
      Prevent KisEngine from injecting it's own
      casefileModalHandler function
    */
    window.KisEngine.casefileModalActivated = true

    if (window.KisEngine.pdfBuilderActivated) return

    window.KisEngine.pdfBuilderActivated = true

    const post = usePostMessage()

    document.addEventListener('click', (event) => {
      const target = event?.target?.closest('[data-casefile-form]')

      console.log('KISENGINE', event, target)

      if (target) {
        event.preventDefault()

        post('kipu_pdf_package', {
          action: 'open',
          config: Object.assign({}, target.dataset),
        })
      }
    })
  }
}
