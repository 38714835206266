/*
use like so:
<HeroIcon icon="plus-circle" class="w-5 h-5 text-blue-500" />
<HeroIcon icon="plus-circle" outline />
*/

import { h } from 'vue'

export default {
  name: 'FragmentWrap',
  props: {
    is: {
      type: [String, null],
      default: null,
    },
  },
  inheritAttrs: false,
  setup (props, { attrs, slots }) {
    return props.is
      ? () => h(props.is, { ...attrs }, slots.default?.())
      : () => slots.default?.() || null
  },
}
