<script setup>
import { computed } from 'vue'
import KIcon from '@/components/kipu-ui/icons/KIcon'

const basicFocusClasses = `
  hover:tw-text-white
  hover:tw-outline-none
  hover:tw-bg-k-true-blue
  hover:tw-border-k-true-blue

  focus:tw-text-white
  focus:tw-outline-none
  focus:tw-bg-k-true-blue
  focus:tw-border-k-true-blue

  focus:tw-ring-2
  focus:tw-ring-offset-2
  focus:tw-ring-k-true-blue
`.split(/\s+/).join(' ')

const specialFocusClasses = `
  hover:tw-text-white
  hover:tw-outline-none
  hover:tw-bg-k-purple
  hover:tw-border-k-purple

  focus:tw-text-white
  focus:tw-outline-none
  focus:tw-bg-k-purple
  focus:tw-border-k-purple

  focus:tw-ring-2
  focus:tw-ring-offset-2
  focus:tw-ring-k-purple
`.split(/\s+/).join(' ')

const props = defineProps({
  mode: {
    type: String,
    validator: function (value) {
      return ['primary', 'secondary', 'special', 'additional', 'icon'].indexOf(value) !== -1
    },
    default: 'primary',
  },
  icon: {
    type: String,
    default: '',
  },

  noShadow: {
    type: Boolean,
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  text: {
    type: String,
    default: 'Text',
  },

  size: {
    type: String,
    validator: function (value) {
      return ['small', 'large'].indexOf(value) !== -1
    },
    default: 'small',
  },
})

const basic = computed(() => {
  return ['primary', 'secondary'].indexOf(props.mode) !== -1
})

const additionalish = computed(() => {
  return ['additional', 'icon'].indexOf(props.mode) !== -1
})

const classes = computed(() => ({
  'tw-py-2': props.mode !== 'icon',
  'tw-inline-flex tw-items-center tw-justify-center tw-px-4 tw-border-2 tw-border-solid tw-font-semibold tw-rounded-full tw-uppercase hover:tw-outline-none focus:tw-outline-none': true,
  'tw-text-sm  tw-max-h-10': props.size === 'small', // max-h fixes when wrapped in nested flex seems to get larger
  'tw-text-base': props.size === 'large',
  [basicFocusClasses]: basic.value,
  [specialFocusClasses]: props.mode === 'special',
  'tw-text-white tw-bg-k-dark-blue tw-border-transparent': props.mode === 'primary',
  'tw-border-k-dark-blue disabled:tw-border-k-dark-blue-300': props.mode === 'secondary',
  'tw-border-k-purple tw-text-k-purple disabled:tw-border-k-purple-300': props.mode === 'special',
  'tw-bg-white': props.mode === 'secondary' || props.mode === 'special',
  'tw-text-k-dark-blue': props.mode !== 'primary' && props.mode !== 'special',
  'disabled:tw-bg-k-gray-300 disabled:tw-text-k-gray-700': !additionalish.value,
  'tw-border-transparent focus:tw-border-k-true-blue focus:tw-bg-white focus:tw-ring-2 focus:tw-ring-offset-2 focus:tw-ring-k-true-blue disabled:tw-bg-white disabled:tw-text-k-gray-300': additionalish.value,
  'hover:tw-bg-k-gray-300': props.mode === 'additional',
  'hover:tw-bg-k-sky-blue tw-py-3': props.mode === 'icon',
  'tw-shadow-md': !props.disabled && !additionalish.value && !props.noShadow,
  'tw-shadow-none': props.disabled || additionalish.value || props.noShadow,
  'tw-cursor-pointer hover:tw-cursor-pointer': !props.disabled,
  'tw-cursor-not-allowed': props.disabled,
}))

const iconClasses = computed(() => ({
  'tw-mr-2': props.mode !== 'icon',
}))

const caption = computed(() => (
  (props.mode !== 'icon') ? props.text : ''
))

</script>

<template>
  <button
    type="button"
    :disabled="disabled"
    :class="classes"
    data-skip-legacy="true"
  >
    <KIcon
      v-if="icon"
      :class="iconClasses"
      :icon="icon"
      theme="none"
    />
    <slot>{{ caption }}</slot>
  </button>
</template>
