/*
  This File was modified from the original version found at:
  https://github.com/vueuse/vueuse/blob/main/packages/core/useWebNotification/index.ts

  The original _always_ requests notification permission on mount. This is not
  a good idea for _any_ notification usage case, and would lead to us getting marked as spam
*/
import { ref } from 'vue'
import {
  isClient,
  createEventHook,
  tryOnMounted,
  tryOnScopeDispose,
  useEventListener,
  useSupported,
} from '@vueuse/core'
import { useCookies } from '@vueuse/integrations/useCookies'
const cookies = useCookies(['KIPU_SKIP_BROWSER_NOTICE_CHECK'], { autoUpdateDependencies: true })
const defaultWindow = isClient ? window : undefined
const disableNotifications = ref(window.skipBrowserNotifications || cookies.get('KIPU_SKIP_BROWSER_NOTICE_CHECK'))

/**
 * Reactive useWebNotification
 *
 * @see https://vueuse.org/useWebNotification
 * @see https://developer.mozilla.org/en-US/docs/Web/API/notification
 * @param title
 * @param defaultOptions of type WebNotificationOptions
 * @param methods of type WebNotificationMethods
 */
export function useWebNotification (defaultOptions = {}) {
  const { window = defaultWindow, closeOnUnload = true, keepVisible = true } = defaultOptions
  let cleanup = null

  const isSupported = useSupported(() => !disableNotifications.value && !!window && 'Notification' in window)

  const notification = ref(null)

  // Request permission to use web notifications:
  const requestPermission = async () => {
    if (!isSupported.value) return

    if ('permission' in Notification && Notification.permission !== 'denied') return await Notification.requestPermission()
    return Notification.permission
  }

  const { on: onClick, trigger: clickTrigger } = createEventHook()
  const { on: onShow, trigger: showTrigger } = createEventHook()
  const { on: onError, trigger: errorTrigger } = createEventHook()
  const { on: onClose, trigger: closeTrigger } = createEventHook()

  // Show notification method:
  const show = async overrides => {
    if (!isSupported.value || Notification.permission !== 'granted') return

    if (closeOnUnload && window) {
      cleanup = useEventListener(window, 'unload', close)
    }

    const options = Object.assign({}, defaultOptions, overrides)
    notification.value = new Notification(options.title || '', options)

    notification.value.onclick = clickTrigger
    notification.value.onshow = showTrigger
    notification.value.onerror = errorTrigger
    notification.value.onclose = closeTrigger

    return notification.value
  }

  // Close notification method:
  const close = () => {
    if (cleanup) cleanup()
    if (notification.value) notification.value.close()
    notification.value = null
  }

  // On mount, attempt to request permission:
  tryOnMounted(async () => {
    if (defaultOptions.tryOnMounted && isSupported.value) await requestPermission()
  })

  // Attempt cleanup of the notification:
  if (closeOnUnload) tryOnScopeDispose(close)

  // Use close() to remove a notification that is no longer relevant to to
  // the user (e.g.the user already read the notification on the webpage).
  // Most modern browsers dismiss notifications automatically after a few
  // moments(around four seconds).
  if (!keepVisible && isSupported.value && window) {
    const document = window.document
    useEventListener(document, 'visibilitychange', e => {
      e.preventDefault()
      if (document.visibilityState === 'visible') {
        // The tab has become visible so clear the now-stale Notification:
        close()
      }
    })
  }

  return {
    isSupported,
    notification,
    show,
    close,
    onClick,
    onShow,
    onError,
    onClose,
    requestPermission,
  }
}
