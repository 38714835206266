import router from '@emr/router'
import * as Base from './Base'

export function customFetch (url, options) {
  options = { ...(options || {}) }
  if (!('router' in options)) options.router = router
  return Base.customFetch(url, options)
}

export function fetchHeadless (url, options) {
  options = { ...(options || {}) }
  if (!('router' in options)) options.router = router
  return Base.fetchHeadless(url, options)
}
