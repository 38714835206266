<script setup>
import { ref, watch } from 'vue'
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  DialogDescription,
} from '@headlessui/vue'
import KButton from '@/components/kipu-ui/buttons/KButton'
import { useWebNotification } from '@/composables/useWebNotification'
import { useCookies } from '@vueuse/integrations/useCookies'
import icon from '@/assets/images/notification-logo.png'

const {
  isSupported,
  show,
  close,
  onClose,
  onShow,
  onClick,
  requestPermission,
} = useWebNotification({
  title: 'Kipu Notifications Test',
  body: 'You have successfully enabled notifications for Kipu.',
  dir: 'auto',
  icon,
  lang: 'en',
  renotify: true,
  requireInteraction: true,
  tag: 'notifications-test',
})
const notificationsPermission = ref(isSupported.value ? Notification.permission : 'denied')
const notificationIsOpen = ref(false)
const isOpen = ref(isSupported.value && notificationsPermission.value === 'default')

function setModalOpen (value) {
  isOpen.value = value && isSupported.value && notificationsPermission.value !== 'denied'
  !value && close()
}

const requestNotificationsPermission = async function () {
  const permission = await requestPermission()
  notificationsPermission.value = permission
  if (permission === 'granted') show()
}

const disableModalOpen = () => {
  setModalOpen(false)
  const url = new URL(window.location.href)
  const oneWeek = 60 * 60 * 24 * 7
  const opts = /\.[^.]+\.[a-zA-Z]+$/.test(url.hostname)
    ? { domain: `.${url.hostname.split('.').slice(-2).join('.')}`, path: '/' }
    : {}

  Object.assign(opts, {
    expires: new Date(Date.now() + oneWeek * 1000),
    maxAge: oneWeek,
    sameSite: 'lax',
  })

  useCookies(['KIPU_SKIP_BROWSER_NOTICE_CHECK']).set('KIPU_SKIP_BROWSER_NOTICE_CHECK', true, opts)
}

watch(isSupported, (permissionsSupported) => {
  permissionsSupported && setModalOpen(Notification.permission === 'default')
})

onClick(close)

onClose(() => {
  notificationIsOpen.value = false
})

onShow(() => {
  notificationIsOpen.value = true
})
</script>
<template>
  <Dialog
    as="div"
    :open="isOpen"
    class="tw-relative tw-z-50"
    @close="isOpen = false"
  >
    <div class="tw-fixed tw-inset-0 tw-flex tw-items-center tw-justify-center tw-p-4 tw-bg-black/30">
      <DialogPanel class="tw-w-full tw-max-w-sm tw-h-25 tw-rounded tw-bg-white tw-p-6 tw-flex tw-flex-col tw-gap-2">
        <DialogTitle class="tw-text-2xl">
          Enable Notifications
        </DialogTitle>
        <DialogDescription v-if="notificationsPermission === 'granted'">
          You have successfully enabled notifications for Kipu.
          You can use the <strong>Toggle</strong> below to test an example notification.
          When you're ready, click <strong>Close</strong> to dismiss this modal.
        </DialogDescription>
        <DialogDescription v-else-if="notificationsPermission === 'default'">
          Click <strong>Enable</strong> and grant permissions in your browser to receive notifications.
          This will allow you to receive warnings and/or alerts such as being notified before you get
          logged out for inactivity.
        </DialogDescription>
        <DialogDescription v-else>
          You currently have disabled system notifications for Kipu in your browser settings.
          To enable notifications, please set them to be allowed in your browser settings.
        </DialogDescription>
        <div class="tw-flex tw-justify-around">
          <KButton
            v-if="notificationsPermission === 'default'"
            @click="requestNotificationsPermission()"
          >
            Enable
          </KButton>
          <KButton
            v-else
            @click="setModalOpen(false)"
          >
            Close
          </KButton>

          <KButton
            v-if="notificationsPermission === 'granted'"
            :mode="notificationIsOpen ? 'secondary' : 'additional'"
            @click="notificationIsOpen ? close() : show()"
          >
            Toggle
          </KButton>
          <KButton
            v-else
            mode="secondary"
            @click="disableModalOpen()"
          >
            Not Now
          </KButton>
        </div>
      </DialogPanel>
    </div>
  </Dialog>
</template>
