export const elementsToHtml = (elements) =>
  [elements]
    .flat()
    .reduce((acc, node) => acc + (
      node.nodeType === Node.COMMENT_NODE
        ? `<!-- ${node.nodeValue || node.textContent} -->`
        : node.outerHTML || node.nodeValue || node.textContent
    ), '')
    .trim()

export function htmlToElement (html) {
  const template = document.createElement('TEMPLATE')
  template.innerHTML = html.trim()
  const child = template.content.firstChild
  template.content.removeChild(child)
  return child
}

const getHeaders = (head) => {
  let removableAt = head?.childNodes?.length || 0
  if (!removableAt) return []
  return [...head?.childNodes]
    .filter((node, idx) => {
      if (node.nodeType === Node.TEXT_NODE) {
        return true
      } else if (node.nodeType === Node.COMMENT_NODE && node.textContent.trim() === 'REMOVABLE START') {
        removableAt = idx
        return true
      } else if (removableAt < idx) {
        return true
      } else {
        return false
      }
    })
}

const getSSRBody = (content) => {
  const body = content.querySelector('#ssr_wrapper') || content.querySelector('body')
  if (body.childNodes.length) body.childNodes[0].id = 'headless-body'
  return [...body.childNodes]
}

export function htmlToElements (html, options) {
  const { js = false, allowRaw = false, normalize = false } = options || { }
  const template = document.createElement('TEMPLATE')
  template.innerHTML = html.trim()
  if (normalize && template.content.querySelector('head')) {
    const headers = getHeaders(template.content.querySelector('head'))
    const body = getSSRBody(template.content)
    template.innerHTML = elementsToHtml([...headers, ...body])
  }
  const nodes = [...template.content.childNodes]
  const jsNodes = []

  const removeElement = (el) => {
    const index = nodes.indexOf(el)
    if (index !== -1) nodes.splice(index, 1)
    el.remove()
  }

  for (const meta of template.content.querySelectorAll('meta')) {
    if (meta.name === 'csrf-token' || meta.name === 'csrf-param') {
      document.head.querySelector(`meta[name="${meta.name}"]`).content = meta.content
      removeElement(meta)
    }
  }

  for (const title of template.content.querySelectorAll('title')) {
    document.head.querySelector('title').innerHTML = title.innerHTML
    removeElement(title)
  }

  for (const script of template.content.querySelectorAll('script')) {
    if (js) {
      console.debug('SCRIPT', script, script.async, script.defer)
      const el = document.createElement('SCRIPT')
      if (script.src) el.src = script.src
      else if (allowRaw) el.text = script.text
      el.type = 'text/javascript'
      el.async = script.async
      jsNodes.push(el)
    }

    removeElement(script)
  }

  template.content.childNodes.forEach(node => template.content.removeChild(node))
  return [nodes, jsNodes]
}

export default htmlToElements
