<script setup>
import { inject, onMounted, ref, watch } from 'vue'
import ErrorBoundary from '@/components/ErrorBoundary'
import PermissionRequest from '@/components/notifications/PermissionRequest'
import TimeoutModal from '@/components/notifications/TimeoutModal'
import BackToTopButton from '@/components/kipu-ui/buttons/BackToTopButton'
import PdfPackage from '@/apps/printable/components/PdfPackage'
import { useDosespotCounterStore } from '@/apps/dosespot/stores/DosespotCounterStore'
import { useLocalStorageListeners } from '@/composables/useLocalStorageListeners'

const dosespotCounterStore = useDosespotCounterStore()

function isSessionPage () {
  return /\/sign[_-](in|out)$/.test(new URL(location).pathname)
}

const enableTimeout = ref(!window.disableSessionTimeout && !isSessionPage())
const enableNewPdfPackage = inject('enableNewPdfPackage')
const { currentUser } = useLocalStorageListeners()
if (isSessionPage()) currentUser.value = 0

watch(currentUser, (newValue) => {
  if (isSessionPage() && newValue) {
    setTimeout(() => {
      window.location.href = '/'
    }, 1000)
  }
}, { immediate: true })

onMounted(() => {
  dosespotCounterStore.onPageMount()
})

</script>

<template>
  <ErrorBoundary v-if="enableTimeout">
    <PermissionRequest />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableTimeout">
    <TimeoutModal />
  </ErrorBoundary>
  <ErrorBoundary v-if="enableNewPdfPackage">
    <PdfPackage />
  </ErrorBoundary>
  <ErrorBoundary>
    <BackToTopButton />
  </ErrorBoundary>
</template>
