export const acronyms = [
  'api',
  'hipaa',
  'http',
  'id',
  'json',
  'pdf',
  'phi',
  'ssl',
  'url',
]

export const acronymize = (s) => s?.replace(new RegExp(acronyms.map(acc => `(?<=^|\\b)${acc}(?=s?\\b)`).join('|'), 'ig'), x => x.toUpperCase())

export const camelize = (s) => s?.replace(/[_-]./g, x => x[1].toUpperCase())

export const underscore = (s) => s?.replace(/([A-Z])/g, '_$1').toLowerCase()

export const pascalize = (s) => camelize(s)?.replace(/^[a-z]/, x => x.toUpperCase())

export const dasherize = (s) => s?.replace(/([A-Z])/g, '-$1').toLowerCase()

export const titleCase = (s) => s?.replace(/([A-Z])/g, ' $1')?.replace(/^./, x => x.toUpperCase())

export const titleize = (s) => s?.split(' ')?.map(word => acronymize(titleCase(word)))?.join(' ')

export const capitalize = (word) => word && (word.charAt(0).toUpperCase() + word.slice(1))

export const transformHashKeys = (value, transform = camelize) => {
  if (!value || typeof value !== 'object' || value instanceof Date) {
    return value
  } else if (Array.isArray(value)) {
    return value.map(item => transformHashKeys(item, transform))
  } else {
    return Object.keys(value).reduce((acc, key) => {
      if (typeof key === 'string') {
        acc[transform(key)] = transformHashKeys(value[key], transform)
      } else {
        acc[key] = transformHashKeys(value[key], transform)
      }
      return acc
    }, {})
  }
}
