import { computed } from 'vue'

// would prefer to use the vueuse core version but we need to fix vue-demi
// in webpack/multi build first with switching
// https://www.vuemastery.com/blog/vue-3-data-down-events-up/
// https://vueuse.org/core/useVModel/ - has different method signature that seeems odd
// forcing you to specify even default name. i think this is better
export function useVModel (props, emit, name = 'modelValue') {
  const modelValue = computed({
    get () {
      return props[name]
    },
    set (value) {
      emit(`update:${name}`, value)
    },
  })
  return modelValue
}
