<script setup>
import { ref, computed, watch } from 'vue'
import { startCase, kebabCase } from 'lodash'
import { ExclamationCircleIcon } from '@heroicons/vue/20/solid'
import { useVModel } from '@/composables/useVModel'

const props = defineProps({
  name: { type: String, required: true },
  label: { type: String, default: '' },
  id: { type: String, default: '' },
  modelValue: { type: String, default: '' },
  validationError: { type: Object, default: null },
  placeholder: { type: String, default: '' },
  type: { type: String, default: 'text' },
  required: { type: Boolean, default: false },
})

const emit = defineEmits(['update:modelValue', 'blur'])
const value = useVModel(props, emit)
const inputValid = ref(true)

const isInvalid = computed(() => {
  return !!props.validationError || !inputValid.value
})

const resolvedId = computed(() => {
  return props.id || kebabCase(props.name) // eg turn rails user[name] to id user-name
})

const resolvedLabel = computed(() => {
  return props.label === undefined ? startCase(props.name) : props.label // eg first_name becomes "First Name"
})

const input = ref()

watch(value, (v) => {
  console.debug('textbox value', v)
  if (!v.length || v.length > 3) {
    input.value.setCustomValidity('')
    input.value.classList[props.required ? 'add' : 'remove']('invalid')
  } else if (v.length < 3) {
    input.value.setCustomValidity('too short')
    input.value.classList.add('invalid')
  }

  inputValid.value = !!input.value.checkValidity()
})
</script>

<template>
  <div>
    <label
      v-if="resolvedLabel"
      :for="resolvedId"
      class="tw-block tw-text-sm tw-font-medium twx-leading-6 tw-text-gray-900"
    >
      {{ resolvedLabel }}
    </label>

    <div class="tw-relative tw-mt-1 tw-rounded-md tw-shadow-sm">
      <input
        :id="resolvedId"
        ref="input"
        v-model="value"
        :type="type"
        :name="name"
        class="
          tw-block tw-w-full
          tw-rounded-md
          tw-py-1.5 tw-px-4
          tw-border-0 tw-ring-1 tw-ring-inset
          focus:tw-ring-2 focus:tw-ring-inset  sm:tw-text-sm sm:tw-leading-6
          invalid:tw-text-red-900 invalid:focus:tw-ring-red-500 invalid:tw-ring-red-300
          invalid:placeholder:tw-text-red-300
        "
        :class="{
          'tw-pr-10': isInvalid,
          'focus:tw-ring-indigo-600': !isInvalid,
        }"
        :placeholder="placeholder"
        :required="required"
        @blur="(ev) => emit('blur', ev)"
      >
      <div class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-3">
        <ExclamationCircleIcon
          v-if="isInvalid"
          class="tw-h-5 tw-w-5 tw-text-red-500"
          aria-hidden="true"
        />
      </div>
    </div>

    <p
      v-if="validationError"
      :id="`${resolvedId}-error`"
      class="tw-mt-2 tw-text-sm tw-text-red-600"
    >
      {{ validationError }}
    </p>
  </div>
</template>
