<script setup>
/* eslint vue/no-v-html: 0 */
import { computed, reactive, ref, watch } from 'vue'
import { customFetch } from '@/lib/utils/Fetch'
import { usePdfPackageData } from '@/composables/usePdfPackageData'
import LoadingCentered from '@/assets/html/loader-wheel-centered.html'
import ModalCentered from '@/components/layout/ModalCentered'
import KButton from '@/components/kipu-ui/buttons/KButton'
import OptionsForm from './OptionsForm'

const props = defineProps({
  patientId: { type: Number, default: 0 },
  type: { type: String, required: true },
})

const {
  buildData,
  loadData,
  loading,
} = usePdfPackageData()

logger.debug('[PDF] Builder', props, buildData)

const isOpen = ref(false)
const modal = ref(null)

const title = computed(() => props.type === 'package' ? 'Generate PDF Package' : 'Generate Casefile')
const openModal = () => { modal?.value?.openModal() }
const closeModal = () => { modal?.value?.closeModal() }
const modalIsOpen = computed(() => modal?.value?.isOpen?.value)

const handleError = (error) => {
  logger.error('[PDF] Error', error)

  if (!buildData.value) {
    buildData.value = reactive({
      error: 'An error occurred loading the requested data. Please try again.',
    })
  }

  if (error.fetchResult) {
    buildData.value.submissionResponse = reactive(Object.assign({}, error.fetchResult, { success: false }))
  } else {
    buildData.value.submissionResponse = reactive({ success: false })
  }
}

const handleSubmission = (config) => {
  try {
    if (config.category === 'review') {
      generateReviewPackage(config)
    } else {
      generateDefaultPackage(config)
    }
  } catch (error) {
    handleError(error)
  }
}

const generateDefaultPackage = ({ patientId, data }) => {
  logger.debug('[PDF] Generating Default Package', data)

  const url = new URL(`/api/emr/patients/${patientId}/casefile`, window.location)

  const request = customFetch(
    url,
    {
      method: 'POST',
      json: true,
      body: JSON.stringify(data),
    },
  )

  loading.value++

  request
    .then(response => response.json())
    .then((data) => {
      delete data.available_params
      buildData.value.submissionResponse = reactive(data)
    })
    .catch(handleError)
    .finally(() => {
      loading.value--
    })
}

const generateReviewPackage = () => {
  // const url = new URL(`/api/emr/patients/${props.patientId}/casefile/review`, window.location)
  throw new Error('Review Package Generation not implemented')
}

watch([props, isOpen], () => {
  if (isOpen.value) loadData({ patientId: props.patientId, type: props.type })

  logger.debug('[PDF] Builder', props, buildData)
})

defineExpose({ isOpen: modalIsOpen, openModal, closeModal })
</script>

<template>
  <ModalCentered
    ref="modal"
    v-model="isOpen"
    close-button-label=""
    :title="title"
  >
    <template #openButton="controls">
      <slot
        name="openButton"
        v-bind="controls"
      />
    </template>

    <div
      class="
        tw-flex-1 tw-h-screen tw-max-h-full
      "
    >
      <div class="tw-w-full tw-h-full">
        <!-- TODO: Port Casefiles Modal to Vue3 -->
        <dl
          v-if="buildData"
          class="tw-hidden"
        >
          <template
            v-for="(value, key) in buildData"
            :key="key"
          >
            <dt>{{ key }}</dt>
            <dd>{{ JSON.stringify(value) }}</dd>
          </template>
        </dl>
        <div
          v-if="loading || buildData?.error || buildData?.submissionResponse?.success"
          class="tw-flex tw-flex-col tw-h-full tw-gap-4"
        >
          <div
            class="
              tw-flex-grow tw-h-px tw-overflow-y-auto
              tw-flex tw-flex-col tw-justify-center
            "
          >
            <div
              v-if="loading"
              v-html="LoadingCentered"
            />
            <div
              v-else-if="buildData?.error"
              class="tw-bg-k-red-300 tw-text-red-900 tw-p-2 tw-mb-2 tw-rounded-md tw-text-center"
            >
              {{ buildData.error }}
            </div>
            <div
              v-else
              class="tw-bg-k-green-300 tw-text-green-900 tw-p-2 tw-mb-2 tw-rounded-md tw-text-center"
            >
              {{ buildData.submissionResponse.status }}
            </div>
          </div>
          <div class="tw-w-full tw-flex tw-justify-end">
            <KButton
              mode="primary"
              type="button"
              @click="closeModal()"
            >
              Close
            </KButton>
          </div>
        </div>
        <OptionsForm
          v-else-if="buildData"
          v-model="buildData"
          :patient-id="props.patientId"
          :type="props.type"
          @submit="handleSubmission($event)"
          @error="handleError($event)"
        >
          <template #footer>
            <KButton
              mode="additional"
              type="button"
              @click="closeModal()"
            >
              Close
            </KButton>
          </template>
        </OptionsForm>
      </div>
    </div>
  </ModalCentered>
</template>
