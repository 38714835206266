import { customFetch } from '@/lib/utils/Fetch/Base'
import { elementsToHtml, htmlToElements } from '@/lib/utils/HtmlParsing'

const definedIcons = {}

const cdn = 'https://cdn.jsdelivr.net/npm/heroicons@2.0.16'

const getUrl = (options = {}) =>
  options?.icon
    ? `${cdn}/${options.mini ? '20' : '24'}/${options.outline ? 'outline' : 'solid'}/${options.icon}.svg`
    : ''

export const fetchHeroIcon = async (options = {}) => {
  try {
    const url = getUrl(options)
    if (!url) return ''
    if (definedIcons[url]) return definedIcons[url]

    const result = await customFetch(url, { credentials: 'omit' })
    const [parsed, _] = htmlToElements(await result.text())
    definedIcons[url] = elementsToHtml(parsed)
    return definedIcons[url]
  } catch (err) {
    console.error(err)
    return ''
  }
}

export default fetchHeroIcon
