if (!window.usePostMessageListener) {
  const subscribers = []
  const dispatch = (event) => {
    subscribers.forEach(
      (callback) => {
        try {
          callback(event)
        } catch (err) {
          logger.error(err)
        }
      },
    )
  }
  window.addEventListener('message', dispatch)

  window.usePostMessageListener = (callback, options) => {
    const { once, allowExternal } = options || {}
    const unsubscribe = () => {
      const index = subscribers.indexOf(callback)
      if (index > -1) subscribers.splice(index, 1)
    }
    if (!allowExternal) {
      const originalCallback = callback
      callback = (event) => {
        if (event?.origin?.toLowerCase() === window.location.origin.toLowerCase()) {
          originalCallback(event)
        }
      }
    }
    if (once) {
      const originalCallback = callback
      let called = false
      callback = (event) => {
        if (!called) originalCallback(event)
        called = true
        unsubscribe()
      }
    }
    subscribers.push(callback)
    return unsubscribe
  }

  window.usePostMessage = (target) => {
    target = target || window.top
    return (type, data) => {
      target.postMessage({ type, ...(data || {}) }, '*')
    }
  }
}

export const usePostMessageListener = window.usePostMessageListener
export const usePostMessage = window.usePostMessage
